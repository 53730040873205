import React, { PropsWithChildren, useState } from "react";

type SelectedTrainingCandidatesContextType = {
  selectedTrainingCandidates: number[];
  selectTrainingCandidates: (slug: number) => void;
  deselectTrainingCandidates: (slug: number) => void;
  selectAll: (slug: number[]) => void;
  deselectAll: (slug: number[]) => void;
  clearSelectedTrainingCandidates: () => void;
};

export const SelectedTrainingCandidatesContext =
  React.createContext<SelectedTrainingCandidatesContextType>({
    selectedTrainingCandidates: [],
    selectTrainingCandidates: () => { },
    deselectTrainingCandidates: () => { },
    selectAll: () => { },
    deselectAll: () => { },
    clearSelectedTrainingCandidates: () => { },
  });

const SelectedTrainingCandidatesContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [selectedTrainingCandidates, setSelectedTrainingCandidates] = useState<number[]>([]);

  const selectTrainingCandidates = (slug: number) => {
    setSelectedTrainingCandidates((preSelectedTrainingCandidates) => {
      if (preSelectedTrainingCandidates.indexOf(slug) === -1) {
        return [...preSelectedTrainingCandidates, slug];
      } else {
        return preSelectedTrainingCandidates;
      }
    });
  };

  const deselectTrainingCandidates = (slug: number) => {
    setSelectedTrainingCandidates((preSelectedTrainingCandidates) =>
      preSelectedTrainingCandidates.filter(
        (preSelectedTrainingCandidates) => preSelectedTrainingCandidates !== slug
      )
    );
  };

  const selectAll = (slugs: number[]) => {
    setSelectedTrainingCandidates((preSelectedTrainingCandidates) => [
      ...preSelectedTrainingCandidates,
      ...slugs.filter((slug) => preSelectedTrainingCandidates.indexOf(slug) === -1),
    ]);
  };

  const deselectAll = (slugs: number[]) => {
    setSelectedTrainingCandidates((preSelectedTrainingCandidates) =>
      preSelectedTrainingCandidates.filter(
        (preSelectedTrainingCandidates) => slugs.indexOf(preSelectedTrainingCandidates) === -1
      )
    );
  };

  const clearSelectedTrainingCandidates = () => {
    setSelectedTrainingCandidates([]);
  };

  return (
    <SelectedTrainingCandidatesContext.Provider
      value={{
        selectedTrainingCandidates,
        selectTrainingCandidates,
        deselectTrainingCandidates,
        selectAll,
        deselectAll,
        clearSelectedTrainingCandidates,
      }}
    >
      {children}
    </SelectedTrainingCandidatesContext.Provider>
  );
};

export default SelectedTrainingCandidatesContextProvider;
