import React, { PropsWithChildren, useState } from "react";
import { SubscriberFilterBarType } from "../components/main/contact/subscribers/SubscriberFilterBar";
import Subscriber from "../types/Subscriber";

type SubscriberContextType = {
  responseData: Subscriber[];
  setResponseData: (subscriber: Subscriber[]) => void;
  loading: boolean;
  setLoading: (status: boolean) => void;
  setSearchInput: (input: string) => void;
  searchInput: string;
  setFilterState: (input: SubscriberFilterBarType) => void;
  filterState: SubscriberFilterBarType;
};

export const SubscriberContext = React.createContext<SubscriberContextType>({
  responseData: [],
  setResponseData: () => {
    //
  },
  loading: false,
  setLoading: () => {
    //
  },
  setSearchInput: () => {
    //
  },
  searchInput: "",

  setFilterState: () => {
    //
  },
  filterState: { dateFrom: null, dateTo: null, status: "" },
});

const SubscriberContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [responseData, setResponseData] = useState<Array<Subscriber>>([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filterState, setFilterState] = useState<SubscriberFilterBarType>({
    dateFrom: null,
    dateTo: null,
    status: "AL",
  });

  return (
    <SubscriberContext.Provider
      value={{
        responseData,
        setResponseData,
        loading,
        setLoading,
        setSearchInput,
        searchInput,
        setFilterState,
        filterState,
      }}
    >
      {children}
    </SubscriberContext.Provider>
  );
};

export default SubscriberContextProvider;
