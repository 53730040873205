import React, { useContext, useEffect, useState } from "react";
import { DEFAULT_DATE } from "../../../../utils/DateUtils";
import StatusBadge from "../../../UI/StatusBadge";
import { StatusBadgeTypes } from "../../../../utils/Constants";
import Moment from "react-moment";
import { Row } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faCommentAltDots, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { faDoNotEnter } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import moment from "moment";
import LastConfirmWarningModal from "../../../UI/LastConfirmWarningModal";
import { SubscriberContext } from "../../../../store/SubscriberContext";
import Subscriber from "../../../../types/Subscriber";
import { SelectedSubscribersContext } from "../../../../store/SelectedSubscribersContext";
import i18next from "i18next";

const SubscribersRow: React.FC<{
  row: Row<Subscriber>;
  unsubscribeButtonHandler: (id: string) => void;
}> = ({ row, unsubscribeButtonHandler }) => {
  const { t } = useTranslation();
  const { email, first_name, last_name, status, subscription_date, id, unsubscription_date } = row.original;
  const [isHovered, setIsHovered] = useState(false);
  const date = subscription_date ? moment(subscription_date, "DD/MM/YYYY HH:mm:ss").toDate() : DEFAULT_DATE;
  const unp_date = unsubscription_date ? moment(unsubscription_date, "DD/MM/YYYY HH:mm:ss").toDate() : DEFAULT_DATE;
  const [showSubscription, setShowSubscription] = useState(false);
  const { loading } = useContext(SubscriberContext);

  const { selectedSubscribers, selectSubscriber, deselectSubscriber } = useContext(SelectedSubscribersContext);

  const checked = selectedSubscribers.indexOf(id) !== -1;

  const yyyy = unp_date.getFullYear();
  const dd = String(unp_date.getDate()).padStart(2, "0");
  const mm = String(unp_date.getMonth() + 1).padStart(2, "0");
  useEffect(() => {
    loading === false && setShowSubscription(false);
  }, [loading]);

  return (
    <>
      <tr className="border-t border-gray-df ">
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141] sticky left-0">
          <div className="flex items-center">
            <div
              className={`table__checkbox grid place-items-center mr-4 ${
                checked && "border-blue-primary bg-blue-primary"
              }`}
              onClick={() => {
                if (checked) {
                  deselectSubscriber(id);
                } else {
                  selectSubscriber(id);
                }
              }}
            >
              {checked && <FontAwesomeIcon icon={faCheck} className="text-sm text-white" />}
            </div>
            {email}
          </div>
        </td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">{first_name}</td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">{last_name} </td>
        <td className="border-t td-firefox border-gray-df table__cell text-sm text-[#414141]">
          {date.getTime() !== DEFAULT_DATE.getTime() && (
            <div>
              <Moment date={date} format="DD/MM/YYYY" locale={i18next.language === "ar" ? "ar" : "en"} />
            </div>
          )}
        </td>
        <td className=" td-firefox border-gray-df table__cell text-sm flex justify-between items-center">
          {(() => {
            switch (status) {
              case "SB":
                return (
                  <div>
                    <StatusBadge type={StatusBadgeTypes.SUBSCRIBE} />
                  </div>
                );

              case "US":
                return (
                  <div>
                    <StatusBadge type={StatusBadgeTypes.UNSUBSCRIBE} />
                  </div>
                );
              case "PD":
                return (
                  <div>
                    <StatusBadge type={StatusBadgeTypes.PENDING} />
                  </div>
                );
            }
          })()}
          <div
            onMouseOver={() => setIsHovered(true)}
            onMouseOut={() => setIsHovered(false)}
            className={`${status === "SB" && "hover:cursor-pointer"} w-4 mx-2 relative`}
            onClick={() => status === "SB" && setShowSubscription(true)}
          >
            {status !== "PD" && (
              <FontAwesomeIcon
                icon={status === "SB" ? faEnvelope : faCommentAltDots}
                className="text-base text-[#414141]"
              />
            )}
            {status === "SB" && (
              <FontAwesomeIcon
                icon={faDoNotEnter}
                className="text-[10px] text-red-primary absolute bottom-0 right-0 -translate-y-[3px] translate-x-[1px]"
              />
            )}
            {status !== "PD" && isHovered && (
              <div className="absolute -top-[28px] right-0 p-1 rounded bg-white shadow">
                {status === "SB" ? t("unsubscribe") : t("unsubscribe_message", { year: yyyy, mm: mm, dd: dd })}
              </div>
            )}
          </div>

          {showSubscription && (
            <LastConfirmWarningModal
              onBackdrop={() => setShowSubscription(false)}
              onCancel={() => setShowSubscription(false)}
              onConfirm={() => unsubscribeButtonHandler(id)}
              titleKey={t("unsubscription")}
              messageKey={t("unsubscription_message")}
              confirmButtonLabelKey={t("yes_unsubscribe")}
            />
          )}
        </td>
      </tr>
    </>
  );
};

export default SubscribersRow;
