import React, { useEffect, useState } from "react";
import Button from "../../../UI/Button";
import { ButtonTypes } from "../../../../utils/Constants";
import DatePicker from "../../../UI/DatePicker";
import Dropdown, { DropdownType } from "../../../UI/Dropdown";
import { useTranslation } from "react-i18next";

export type SubscriberFilterBarType = {
  dateFrom: Date | null;
  dateTo: Date | null;
  status: string;
};
const SubscriberFilterBar: React.FC<{
  applyHandler: (filter: SubscriberFilterBarType) => void;
  resetHandler: () => void;
  statusValue: string;
  dateValue: { dateFrom: Date | null; dateTo: Date | null };
}> = ({ applyHandler, resetHandler, statusValue, dateValue }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState("AL");
  const [date, setDate] = useState<{
    dateFrom: Date | null;
    dateTo: Date | null;
  }>({ dateFrom: null, dateTo: null });
  const [applyIsClicked, setApplyIsClicked] = useState(false);
  useEffect(() => {
    setDate(dateValue);
  }, [dateValue]);
  useEffect(() => {
    setStatus(statusValue);
  }, [statusValue]);
  return (
    <div className="w-95/100 flex flex-wrap justify-between gap-x-6 mx-auto">
      <div className="flex flex-wrap gap-x-6 gap-y-1">
        <div>
          <label className="text-sm text-black41">
            {t("subscription_date")}
          </label>
          <div className="flex gap-3" dir="ltr">
            <DatePicker
              inputClassName="w-[124px] placeholder:text-gray-d7"
              containerClassName="w-[124px]"
              selected={date.dateFrom}
              placeholderText={t("from")}
              onChange={(value) => {
                setDate({ ...date, dateFrom: value });
              }}
              maxDate={date.dateTo}
            />

            <DatePicker
              inputClassName="w-[124px] placeholder:text-gray-d7"
              containerClassName="w-[124px]"
              selected={date.dateTo}
              placeholderText={t("to")}
              onChange={(value) => {
                setDate({ ...date, dateTo: value });
              }}
              minDate={date.dateFrom}
            />
          </div>
        </div>

        <div>
          <label className="text-sm text-black41">{t("status")}</label>
          <Dropdown
            type={DropdownType.PRIMARY}
            onOptionSelected={(option) => {
              setStatus(option);
            }}
            keys={["AL", "SB", "PD", "US"]}
            selectedKey={status}
          />
        </div>
      </div>
      <div className="flex flex-row-reverse">
        <div>
          {applyIsClicked &&
            (status !== "AL" ||
              date.dateFrom !== null ||
              date.dateTo !== null) && (
              <button
                onClick={() => {
                  resetHandler();
                  setStatus("AL");
                  setDate({ dateFrom: null, dateTo: null });
                  setApplyIsClicked(false);
                }}
                className="text-blue-primary hover:font-semibold text-sm mr-6"
              >
                {t("reset")}
              </button>
            )}

          <Button
            className="mt-6"
            type={ButtonTypes.PRIMARY}
            onClick={() => {
              setApplyIsClicked(true);
              applyHandler({
                dateFrom: date.dateFrom,
                dateTo: date.dateTo,
                status: status,
              });
            }}
          >
            {t("apply")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SubscriberFilterBar;
