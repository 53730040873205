import React, { useContext, useEffect, useState } from "react";
import Button from "../../../UI/Button";
import { ButtonTypes } from "../../../../utils/Constants";
import DatePicker from "../../../UI/DatePicker";
import Dropdown, { DropdownType } from "../../../UI/Dropdown";
import { useTranslation } from "react-i18next";
import { TrainingCandidatesContext } from "../../../../store/TrainingCandidatesContext";

export type TrainingCandidatesFilterBarType = {
  referral: string;
  dateFrom: Date | null;
  dateTo: Date | null;
  status: string;
  country: string;
};

const TrainingCandidatesFilterBar: React.FC<{
  applyHandler: (filter: TrainingCandidatesFilterBarType) => void;
  resetHandler: () => void;
  referralValue: string;
  dateValue: { dateFrom: Date | null; dateTo: Date | null };
  statusValue: string;
  countryValue: string;
}> = ({ applyHandler, resetHandler, referralValue, dateValue, statusValue, countryValue }) => {
  const { t } = useTranslation();
  const [referral, setReferral] = useState("AL");
  const [status, setStatus] = useState("AL");
  const [date, setDate] = useState<{
    dateFrom: Date | null;
    dateTo: Date | null;
  }>({ dateFrom: null, dateTo: null });
  const [country, setCountry] = useState("AL");
  const [applyIsClicked, setApplyIsClicked] = useState(false);
  const {
    referralList,
    countryList,
  } = useContext(TrainingCandidatesContext);

  useEffect(() => {
    setReferral(referralValue);
  }, [referralValue]);

  useEffect(() => {
    setDate(dateValue);
  }, [dateValue]);

  useEffect(() => {
    setStatus(statusValue);
  }, [statusValue]);

  useEffect(() => {
    setCountry(countryValue);
  }, [countryValue]);

  return (
    <div className="w-95/100 flex flex-wrap justify-between gap-x-6 mx-auto">
      <div className="flex flex-wrap gap-x-6 gap-y-1">
        {referralList.length > 0 && (
          <div>
            <label className="text-sm text-black41">{t("referral_source")}</label>
            <Dropdown
              type={DropdownType.PRIMARY}
              extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
              onOptionSelected={(option) => {
                setReferral(option);
              }}
              keys={["AL", ...referralList.map((referral) => referral[0])]}
              selectedKey={referral}
              getValueByKey={(key) => {
                if (key === "AL") return t("all");
                const foundReferral = referralList.find((referral) => referral[0] === key);
                if (foundReferral) {
                  return foundReferral[1];
                } else {
                  console.warn(`Referral with key "${key}" not found.`);
                  return "";
                }
              }}
            />
          </div>
        )}

        <div>
          <label className="text-sm text-black41">{t("contact_date")}</label>
          <div className="flex gap-x-3" dir="ltr">
            <DatePicker
              inputClassName="w-[122px] placeholder:text-gray-d7"
              containerClassName="w-[122px]"
              selected={date.dateFrom}
              placeholderText={t("from")}
              onChange={(value) => {
                setDate({ ...date, dateFrom: value });
              }}
              maxDate={date.dateTo}
            />

            <DatePicker
              inputClassName="w-[122px] placeholder:text-gray-d7"
              containerClassName="w-[122px]"
              selected={date.dateTo}
              placeholderText={t("to")}
              onChange={(value) => {
                setDate({ ...date, dateTo: value });
              }}
              minDate={date.dateFrom}
            />
          </div>
        </div>

        <div>
          <label className="text-sm text-black41">{t("status")}</label>
          <Dropdown
            type={DropdownType.PRIMARY}
            onOptionSelected={(option) => {
              setStatus(option);
            }}
            keys={["AL", "PD", "RS"]}
            selectedKey={status}
          />
        </div>

        {countryList.length > 0 &&
          <div>
            <label className="text-sm text-black41">{t("country")}</label>
            <Dropdown
              type={DropdownType.PRIMARY}
              extraAvailableOptionsContainerClassName="top-12 h-[125px] overflow-auto"
              onOptionSelected={(option) => {
                setCountry(option);
              }}
              keys={["AL", ...countryList.map((country) => country[0])]}
              selectedKey={country}
              getValueByKey={(key) => {
                if (key === "AL") return t("all");
                const foundCountry = countryList.find((country) => country[0] === key);
                if (foundCountry) {
                  return foundCountry[1];
                } else {
                  console.warn(`country with key "${key}" not found.`);
                  return "";
                }
              }}
            />
          </div>
        }
      </div>
      <div className="flex flex-grow justify-end mt-6 gap-x-6">
        {applyIsClicked &&
          (referral !== "AL" ||
            date.dateFrom !== null ||
            date.dateTo !== null || status !== "AL" || country !== "AL") && (
            <button
              onClick={() => {
                resetHandler();
                setReferral("AL");
                setDate({ dateFrom: null, dateTo: null });
                setStatus("AL");
                setCountry("AL");
                setApplyIsClicked(false);
              }}
              className="text-blue-primary hover:font-semibold text-sm"
            >
              {t("reset")}
            </button>
          )}

        <Button
          type={ButtonTypes.PRIMARY}
          onClick={() => {
            setApplyIsClicked(true);
            applyHandler({
              referral: referral,
              dateFrom: date.dateFrom,
              dateTo: date.dateTo,
              status: status,
              country: country,
            });
          }}
        >
          {t("apply")}
        </Button>
      </div>
    </div>
  );
};

export default TrainingCandidatesFilterBar;
