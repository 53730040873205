import React, { PropsWithChildren, useState } from "react";
import TrainingCandidate from "../types/TrainingCandidate";
import { TrainingCandidatesFilterBarType } from "../components/main/contact/trainingCandidates/TrainingCandidatesFilterBar";

type TrainingCandidatesContextType = {
  responseData: TrainingCandidate[];
  setResponseData: (TrainingCandidates: TrainingCandidate[]) => void;
  referralList: [string, string][];
  setReferralList: (ReferralList: [string, string][]) => void;
  organizationList: [string, string][];
  setOrganizationList: (OrganizationList: [string, string][]) => void;
  countryList: [string, string][];
  setCountryList: (CountryList: [string, string][]) => void;
  loading: boolean;
  setLoading: (status: boolean) => void;
  setSearchInput: (input: string) => void;
  searchInput: string;
  setFilterState: (input: TrainingCandidatesFilterBarType) => void;
  filterState: TrainingCandidatesFilterBarType;
};

export const TrainingCandidatesContext = React.createContext<TrainingCandidatesContextType>({
  responseData: [],
  setResponseData: () => {
    //
  },
  referralList: [],
  setReferralList: () => {
    //
  },
  organizationList: [],
  setOrganizationList: () => {
    //
  },
  countryList: [],
  setCountryList: () => {
    //
  },
  loading: false,
  setLoading: () => {
    //
  },
  setSearchInput: () => {
    //
  },
  searchInput: "",
  setFilterState: () => {
    //
  },
  filterState: { referral: "", dateFrom: null, dateTo: null, status: "", country: "" },
});

const TrainingCandidatesContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [responseData, setResponseData] = useState<Array<TrainingCandidate>>([]);
  const [referralList, setReferralList] = useState<[string, string][]>([]);
  const [organizationList, setOrganizationList] = useState<[string, string][]>([]);
  const [countryList, setCountryList] = useState<[string, string][]>([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filterState, setFilterState] = useState<TrainingCandidatesFilterBarType>({
    referral: "AL",
    dateFrom: null,
    dateTo: null,
    status: "AL",
    country: "AL"
  });

  return (
    <TrainingCandidatesContext.Provider
      value={{
        responseData,
        setResponseData,
        referralList,
        setReferralList,
        organizationList,
        setOrganizationList,
        countryList,
        setCountryList,
        loading,
        setLoading,
        setSearchInput,
        searchInput,
        setFilterState,
        filterState,
      }}
    >
      {children}
    </TrainingCandidatesContext.Provider>
  );
};

export default TrainingCandidatesContextProvider;
